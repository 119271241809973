import React, { Suspense } from 'react';
import Buildcard from '../../components/buildcard/buildcard';
import GGSelect, { GGSelectOptionProps } from '../../components/GGSelect/GGSelect';
import Curtain from '../../components/curtain/curtain';
import { names, sortOptions } from '../../helpers/data';
import './ViewBuilds.css';
import SquareLoader from '../../components/SquareLoader/SquareLoader';

const buildsUrl = 'https://3tcfjx3la7.execute-api.us-west-1.amazonaws.com/default/getAllBuilds?TableName=builds';

class ViewBuilds extends React.Component<{}, any> {
    selectStyle: React.CSSProperties = { "margin": "auto", "marginTop": "20px", "zIndex": "2" }
    characterOptions: Array<GGSelectOptionProps> = [];
    sortByOptions: Array<GGSelectOptionProps> = [];
    cards: any;
    cardsLoaded: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            characterSelectActive: false,
            sortBySelectActive: false,
            selectedCharacter: '',
            selectedSort: '',
            builds: {}
        }
        names.forEach( name => {
            this.characterOptions.push({
                label: name, value: name
            });
        });
        sortOptions.forEach( option => {
            this.sortByOptions.push({
                label: option, value: option
            })
        });
        fetch(buildsUrl)
            .then(response => response.json())
            .then(data => this.setState({ builds: data.Items}))
            .finally( () => this.cardsLoaded = true)
        this.cards = this.renderCards();
    }

    renderCards() {
        return Object.keys(this.state.builds).map( (i) => {
            // console.log(this.state.builds[i])
            return (
                    <Buildcard 
                        key={i}
                        title={this.state.builds[i].title}
                        votes={{
                            likes: this.state.builds[i].likes,
                            dislikes: this.state.builds[i].dislikes
                        }}
                        char={this.state.builds[i].character}
                        path={this.state.builds[i].id}
                        comments={this.state.builds[i].comments} />
            )
        })
    }

    handleSelect(sortType: 'character' | 'sort') {
        switch (sortType) {
            case 'character':
                this.setState(
                    {
                        characterSelectActive: !this.state.characterSelectActive,
                        sortBySelectActive: false
                    }
                );
                break;
            case 'sort':
                this.setState({
                    characterSelectActive: false,
                    sortBySelectActive: !this.state.sortBySelectActive
                });
                break;
            default:
                console.error("error in handleSort method");
        }
    }

    handleCurtainClick() {
        this.setState({
            characterSelectActive: false,
            sortBySelectActive: false
        })
    }

    setItem(sortType: 'character' | 'sort', selectedItem: GGSelectOptionProps) {
        switch (sortType) {
            case 'character':
                this.setState({
                    selectedCharacter: selectedItem,
                    characterSelectActive: false,
                });
                break;
            case 'sort':
                this.setState({
                    selectedSort: selectedItem,
                    sortBySelectActive: false
                });
                break;
            default:
                console.error("error in setItem method");
                break;
        }
    }

    render() {
        return (
            <>
                <section className="selects">
                    <h1 className="greeting">
                        View user-created builds for Risk Of Rain 2 <br/>
                    </h1>
                    { 
                        this.state.characterSelectActive || this.state.sortBySelectActive 
                        ? <Curtain handleClick={() => this.handleCurtainClick()} />
                        : null
                    }
                    <GGSelect
                        isActive={this.state.characterSelectActive}
                        setActive={() => this.handleSelect('character')}
                        selectedItem={this.state.selectedCharacter}
                        setSelectedItem={(selectedItem) => this.setItem('character', selectedItem)}
                        placeholder="Select Character"
                        options={this.characterOptions}
                        style={this.selectStyle}></GGSelect>
                    <GGSelect
                        isActive={this.state.sortBySelectActive}
                        setActive={() => this.handleSelect('sort')}
                        selectedItem={this.state.selectedSort}
                        setSelectedItem={(selectedItem) => this.setItem('sort', selectedItem)}
                        placeholder="Sort By" 
                        options={this.sortByOptions}
                        style={this.selectStyle}></GGSelect>
                </section>
                <section className="builds">
                    {
                        this.cardsLoaded ? this.renderCards() : <SquareLoader />
                    }
                </section>
            </>
            

        )
    }

}

export default ViewBuilds;