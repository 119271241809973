import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/Home/Home';
import ViewBuilds from './pages/ViewBuilds/ViewBuilds';
import BuildInfo from './pages/BuildInfo/BuildInfo';
// import {
//   ApolloProvider,
//   ApolloClient,
//   InMemoryCache,
//   gql
// } from '@apollo/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// const client = new ApolloClient({
//   uri: "localhost:4000",
//   cache: new InMemoryCache()
// });

// client.query({ 
//   query: gql`
//     query Users {
//       users {
//         email
//         id
//       }
//     }
//   `,
// }).then((result) => console.log(`!!!!! ${result}`));


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "",
        element: <ViewBuilds />
      },
      {
        path: "/buildinfo/:id",
        element: <BuildInfo />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
