import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import './navbar.css';

export interface NavbarInterface {
    titleStr?: string;
    titleImg?: string;
    signupOptions: any;
    loginOptions: any;
    children: any; // added for routing, still not sure why needed
}


const Navbar = (props: NavbarInterface) => { 

    const nav = useNavigate();
    const [signupSelected, setSignupHighlight] = useState(false);
    const [loginSelected, setLoginHighlight] = useState(false);

    useEffect(() => {
        switch(window.location.pathname) {
            case '/':
                setLoginHighlight(false);
                setSignupHighlight(false);
                break;
            case '/signup':
                setSignupHighlight(true);
                setLoginHighlight(false);
                break;
            case '/login':
                setLoginHighlight(true);
                setSignupHighlight(false);
                break;
        }
    }, [nav])
    

    return (
    <nav>
        {
            props.titleImg 
            ? <Link to="/"> <img className="navImg" src={process.env.PUBLIC_URL + props.titleImg} /> </Link>
            :  <h1>
                    <Link 
                        to="/" 
                        className='title'><b>{props.titleStr}</b>
                    </Link> 
                </h1>
        }
        <section className="user-auth-box">
            <Link 
                id="signup" 
                className='link'
                style={{
                    fontWeight: signupSelected ? 'bold' : 'normal'
                }}
                to={props.signupOptions.path}>
                {props.signupOptions.label}
            </Link>
            |
            <Link 
                id="login" 
                className='link' 
                style={{
                    fontWeight: loginSelected ? 'bold' : 'normal'
                }}
                to={props.loginOptions.path}>
                {props.loginOptions.label}
            </Link>
        </section>
    </nav>)
}

export default Navbar;
