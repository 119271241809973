import { Link } from 'react-router-dom';
import { IVotes, IBuildcard, CHARACTER_IMAGE_PATHS } from '../../interfaces/buildcard/Buildcard';

import './buildcard.css';

export interface characterHeroInterface {
    char: CHARACTER_IMAGE_PATHS;
}



function getAttributesFor(char: CHARACTER_IMAGE_PATHS) {
    let src: string = 'character_icons/' + char + '.png';
    let alt: string = char.slice(0, char.indexOf('.'));
    return {src, alt}; 
}


const Buildcard = (data: IBuildcard) => {
    let imgAttributes = getAttributesFor(data.char);

    return (
        <>
            <Link to={"/buildinfo/" + data.path} style={{ textDecoration: 'none' }}>
                <section className="buildcard" onClick={(path) => { /* Navigate to path */ }}>
                    <img src={imgAttributes.src} alt={imgAttributes.alt} className="buildcard-image" />
                    <aside className="buildcard-aside">
                        <h3 className="buildcard-aside-title-container">{data.title}</h3>
                        <div className="buildcard-aside-likes-container">
                            <span className="buildcard-likes">
                                👍🏾 {data.votes.likes}
                            </span>
                            <span className="buildcard-dislikes">
                                👎🏾 {data.votes.dislikes}
                            </span>
                        </div>
                        <div className="buildcard-aside-comments-container">
                            {data.comments} comments
                        </div>
                        
                    </aside>
                </section>
                <div className="buildcard-presentation"></div>
            </Link>
        </>
    )
}

export default Buildcard;