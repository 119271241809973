import { Outlet } from 'react-router-dom'
import Navbar from '../../components/navbar/navbar';
import { NavbarOptions } from '../../interfaces/NavbarOptionsInterface';
import './Home.css';
  

let signupOptions:NavbarOptions = {
    label: 'Sign Up',
    path: 'https://test-ashaun.auth.us-west-1.amazoncognito.com/signup?client_id=7qasdoht2344r1lp34ac50a2aj&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.flamebuilds.com%2F'
};

let loginOptions: NavbarOptions = {
    label: 'Log In',
    path: 'https://test-ashaun.auth.us-west-1.amazoncognito.com/login?client_id=7qasdoht2344r1lp34ac50a2aj&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fwww.flamebuilds.com%2F'
};

export const Home = () => 
    <>
        <Navbar 
            titleImg="banner-logo2.png" 
            signupOptions={signupOptions}
            loginOptions={loginOptions}>
        </Navbar>
        <Outlet/>
    </>
    
export default Home;
