import './curtain.css';

interface CurtainProps {
    handleClick: () => void
}

const Curtain = (props: CurtainProps) => {
    return (
        <div 
            className="curtain"
            onClick={props.handleClick}></div>
    )
}

export default Curtain;