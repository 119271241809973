import React, { FC } from 'react';
import './SquareLoader.css';

interface SquareLoaderProps {}

const SquareLoader: FC<SquareLoaderProps> = () => (
  <div className="SquareLoader" data-testid="SquareLoader">
    <div className="square square-one"></div>
    <div className="square square-two"></div>
  </div>
);

export default SquareLoader;
