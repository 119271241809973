import React from 'react';
import './GGSelect.css'

export interface GGSelectProps {
    placeholder?: string;
    options?: Array<GGSelectOptionProps>;
    style?: any;
    isActive: string;
    setActive: () => void,
    selectedItem: GGSelectOptionProps
    setSelectedItem: (selectedItem:  GGSelectOptionProps) => void
}

export interface GGSelectOptionProps {
    label: string;
    value: string;
}

export function GGSelect(props: GGSelectProps) {

    const listItems: any = props.options ? props.options.map( child => {
        return <li 
            className="ggoptions-listbox" 
            onClick={() => props.setSelectedItem(child)}
            key={child.value}>
                <b>{child.label}</b>
        </li>
    }) : null;

    const listBox = <div className="ggoptions">
        <ul className="ggoptions-inner-box">
            { listItems }
        </ul>
    </div>

    return(
        <>
           <div
            onClick={() => props.setActive()}
            className="ggselect" 
            tabIndex={0} role="button" 
            style={props.style}>
                <span className="ggselect-placeholder"><b>{ props.selectedItem.label ? props.selectedItem.label : props.placeholder?.concat('...')}</b></span>
                <span className="ggselect-icon">V</span>
           </div>
           { props.isActive ? listBox : null }
        </>
    )
}

export default GGSelect;