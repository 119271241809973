import React from 'react';
import { Link } from 'react-router-dom';
import GGSelect, { GGSelectOptionProps } from '../../components/GGSelect/GGSelect';
import './BuildInfo.css'

let buildsUrl: string = "https://3tcfjx3la7.execute-api.us-west-1.amazonaws.com/default/getAllBuilds?TableName=builds";

class BuildInfo extends React.Component<{}, any> {
    creator: string = "ashaunthomas"
    likes: number = 9;
    dislikes: number = 3;
    buildBlurb: string = `This is a description lorem ipsum`; // character limit: 33


    difficultyOptions: Array<GGSelectOptionProps> = [
        {label: 'Drizzle', value: 'drizzle'},
        {label: 'Rainstorm', value: 'rainstorm'},
        {label: 'Monsoon', value: 'monsoon'},

    ]

    constructor(props: any) {
        super(props);
        this.state = {
            difficultySelectActive: false,
            selectedDifficulty: 'Expert',
            levelSelectActive: false,
            selectedLevel: '18',
        }

        // WARNING: Potential security issue from non-sanitized input handling. 
        // This needs to be adjusted before production

        let index = window.location.href.lastIndexOf('/') + 1;
        let id = window.location.href.substring(index);
        buildsUrl = buildsUrl + "&id=" + id;
        fetch(buildsUrl)
        .then(response => response.json())
            .then(data => {
                this.setState({
                    title: data.Item.title,
                    likes: data.Item.likes,
                    dislikes: data.Item.dislikes,
                    character: data.Item.character,
                    commentsCount: data.Item.comments
                });
                console.log(data.Item);  
            })
    }

    handleSelect(sortType: 'difficulty' | 'level') {
        switch (sortType) {
            case 'difficulty':
                this.setState(
                    {
                        difficultySelectActive: !this.state.difficultySelectActive,
                        levelSelectActive: false
                    }
                );
                break;
            case 'level':
                this.setState({
                    difficultySelectActive: false,
                    levelSelectActive: !this.state.levelSelectActive
                });
                break;
            default:
                console.error("error in handleSort method");
        }
    }

    setItem(sortType: 'difficulty' | 'level', selectedItem: GGSelectOptionProps) {
        switch (sortType) {
            case 'difficulty':
                this.setState({
                    selectedDifficulty: selectedItem,
                    difficultySelectActive: false,
                });
                break;
            case 'level':
                this.setState({
                    selectedLevel: selectedItem,
                    levelSelectActive: false
                });
                break;
            default:
                console.error("error in setItem method");
                break;
        }
    }

    render() {
        return (
            <div className="info">
                <section className="overview">
                    <div className='left-panel'>
                        <img className="buildinfo-character" src={"/character_icons/" + this.state.character + ".png"} />
                        <p><u>Creator </u>{this.creator}</p>
                        <div className='buildinfo-vote-buttons'>
                            <button className="likes">
                                👍🏾 {this.state.likes}
                            </button>
                            <button className="dislikes">
                                👎🏾 {this.state.dislikes}
                            </button>
                        </div>
                    </div>
                    <div className='right-panel'>
                        <h1>{this.state.title}</h1>
                        <p>{this.buildBlurb}</p>
                        <GGSelect 
                            isActive={this.state.difficultySelectActive} 
                            setActive={() => this.handleSelect('difficulty')}
                            selectedItem={this.state.selectedDifficulty}
                            setSelectedItem={(selectedItem) => this.setItem('difficulty', selectedItem)}
                            placeholder="Select Difficulty"
                            options={this.difficultyOptions}
                            style={{ 
                                marginLeft: '50%',
                                transform: 'translateX(-50%)',
                                height: '15px'
                            }}
                         />
                         <GGSelect 
                            isActive={this.state.difficultySelectActive} 
                            setActive={() => this.handleSelect('difficulty')}
                            selectedItem={this.state.selectedDifficulty}
                            setSelectedItem={(selectedItem) => this.setItem('difficulty', selectedItem)}
                            placeholder="Select Difficulty"
                            options={this.difficultyOptions}
                            style={{ 
                                marginLeft: '50%',
                                marginTop: '1vh',
                                transform: 'translateX(-50%)',
                                height: '15px'
                            }}
                         />
                        
                    </div>
                </section>
                <section className="composition">
                    <div className="stats">
                        <p className="stats-label">Health</p>
                        <p className="stats-value">100</p>
                        <p className="stats-label">Regen</p>
                        <p className="stats-value">1.5/s</p>
                        <p className="stats-label">Damage</p>
                        <p className="stats-value">140</p>
                        <p className="stats-label">Speed</p>
                        <p className="stats-value">7 m/s</p>
                        <p className="stats-label">Armor</p>
                        <p className="stats-value">48</p>
                    </div>
                    <div className="itemlist">
                        <picture>
                            <source srcSet="/items_icons/57_Leaf_Clover.webp"></source>
                        </picture>
                        <img className="item" src="/items_icons/57_Leaf_Clover.webp" alt="" />
                        <img className="item" src="/items_icons/Crowbar.webp" alt="" />
                        <img className="item" src="/items_icons/Chronobauble.webp" alt="" />
                        <img className="item" src="/items_icons/Energy_Drink.webp" alt="" />
                        <img className="item" src="/items_icons/Brittle_Crown.webp" alt="" />
                        
                        <img className="item" src="items_icons/Eulogy_Zero.webp" alt="" />
                        <img className="item" src="items_icons/Frost_Relic.webp" alt="" />
                        <img className="item" src="items_icons/Armor-Piercing_Rounds.webp" alt="" />
                        <img className="item" src="items_icons/Ceremonial_Dagger.webp" alt="" />
                        <img className="item" src="items_icons/Corpsebloom.webp" alt="" />

                        <img className="item" src="items_icons/Ancestral_Incubator.webp" alt="" />
                        <img className="item" src="items_icons/Bens_Raincoat.webp" alt="" />
                        <img className="item" src="items_icons/Bison_Steak.webp" alt="" />
                        <img className="item" src="items_icons/Brilliant_Behemoth.webp" alt="" />
                        <img className="item" src="items_icons/Bustling_Fungus.webp" alt="" />

                        <img className="item" src="items_icons/Defense_Nucleus.webp" alt="" />
                        <img className="item" src="items_icons/Defiant_Gouge.webp" alt="" />
                        <img className="item" src="items_icons/Delicate_Watch.webp" alt="" />
                        <img className="item" src="items_icons/Gasoline.webp" alt="" />
                        <img className="item" src="items_icons/Genesis_Loop.webp" alt="" />
                    </div>
                </section>
                <section className="details">
                    <div className="effects"></div>
                    <div className="radar"></div>
                </section>
                <section className="controls">
                    <button className="view-comments">View {this.state.commentsCount} Comments</button>
                    <Link to="/">
                        <button className="back">&lt; Back</button>
                    </Link>
                </section>
            </div>
            
        )
    }
}

export default BuildInfo;